import React from "react"
import Icon from "./Icon"

const Navbar = () => {
  return (
    <nav className="flex justify-between items-center px-5 lg:px-28 py-3 lg:py-6">
      <a href="/">
        <Icon name="logo" className="w-36 lg:w-auto" />
      </a>
      <a href="/#requirement">
        <button className="btn border bg-white bg-opacity-10 hover:bg-opacity-20">
          Start Now
        </button>
      </a>
    </nav>
  )
}

export default Navbar
