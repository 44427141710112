import React, { useState } from "react"
import Book from "../components/Book"
import Clients from "../components/Clients"
import Footer from "../components/common/Footer"
import Contact from "../components/Contact"
import Newsletter from "../components/Newsletter"
import Hero from "../components/Hero"
import Portfolio from "../components/Portfolio"
import Pricing from "../components/Pricing"
import Quoting from "../components/Quoting"
import Requirement from "../components/Requirement"
import Testimonials from "../components/Testimonials"
import WhyUs from "../components/WhyUs"
import Working from "../components/Working"
import blobs from "../components/blobs"
import SEO from "../components/common/SEO"


function Home() {

  const [formData, setFormData] = useState({
    sessionType: "",
    duration: "",
    time: "",
    name: "",
    email: "",
    phone: "",
  })
  const [selected, setSelected] = useState(0)

  return (
    <>
    <SEO 
      title="Meliorist Developers | Sophisticated Software Solutions"
      description="We design and build sophisticated software solutions to help clients achieve long-term goals. Specializing in software development, startup acceleration, and more."
      url="/"
    />
    <main>
      <Hero />
      <Working />
      <WhyUs />
      <Requirement setFormData={setFormData} setSelected={setSelected} />
      <Pricing />
      <Quoting />
      <Book
        formData={formData}
        setFormData={setFormData}
        selected={selected}
        setSelected={setSelected}
      />
      <Portfolio />
      {/* <Testimonials /> */}
      <Clients />
      <Contact />
      <Newsletter />
      <Footer />
      {blobs.map((blob) => blob)}

    </main>
    </>
  )
}

export default Home