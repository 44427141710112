import { useForm } from "@formspree/react"
import React from "react"
import Icon from "./common/Icon"
import Section from "./common/Section"

const Contact = () => {
  const [state, handleSubmit] = useForm("myyajaaa")

  return (
      <Section id="contact" className="small-container">
        <div className="w-full flex flex-col lg:flex-row gap-10">
          <div className="lg:w-2/5 flex flex-col justify-between">
            <div className="mb-5 lg:mb-20">
              <h3 className="text-4xl lg:text-5xl font-bold mb-3">
                Contact Us
              </h3>
              <p className="text-lg lg:text-xl">
                Fill up the form and our team will get back to you within 24
                hours.
              </p>
            </div>

            <div className="lg:flex flex-col gap-6 hidden">
              <a
                href="//linkedin.com/company/meliorist-developers"
                className="flex gap-3 text-xl items-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="linkedin" />
                meliorist-developers
              </a>
              <a
                href="//twitter.com/meliorist_dev"
                className="flex gap-3 text-xl items-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="twitter" />
                @meliorist_dev
              </a>
              <a
                href="//discord.gg/5WPmzv9UKe"
                className="flex gap-3 text-xl items-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="discord" />
                #meliorist.dev
              </a>
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            className="lg:w-3/5 bg-white rounded-2xl text-black p-4 lg:p-8"
          >
            <div className="flex flex-col gap-3 mb-5">
              <div className="flex gap-3 w-full">
                <input
                  type="text"
                  name="firstName"
                  className="rounded-lg border w-1/2 p-3"
                  placeholder="First Name"
                />
                <input
                  type="text"
                  name="lastName"
                  className="rounded-lg border w-1/2 p-3"
                  placeholder="Last Name"
                />
              </div>
              <input
                type="number"
                name="phoneNumber"
                className="rounded-lg border w-full p-3"
                placeholder="Phone Number"
              />
              <input
                type="email"
                name="email"
                className="rounded-lg border w-full p-3"
                placeholder="Enter email"
              />
              <textarea
                name="message"
                className="rounded-lg border w-full p-3 h-32"
                placeholder="Message"
              />
            </div>
            <button
              disabled={state.submitting}
              type="submit"
              className="w-full bg-primary rounded-lg text-white text-lg font-semibold py-2"
            >
              {state.submitting
                ? "Submitting..."
                : state.succeeded
                ? "Submitted!"
                : "SEND MESSAGE"}
            </button>
          </form>
          <div className="flex gap-6 lg:hidden justify-center">
            <a
              href="//linkedin.com/company/meliorist-developers"
              className="flex gap-3 text-xl items-center"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="linkedin" />
            </a>
            <a
              href="//twitter.com/meliorist_dev"
              className="flex gap-3 text-xl items-center"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="twitter" />
            </a>
            <a
              href="//discord.gg/5WPmzv9UKe"
              className="flex gap-3 text-xl items-center"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="discord" />
            </a>
          </div>
        </div>
      </Section>
  )
}

export default Contact
