import React, { useState } from "react"
import Icon from "./common/Icon"
import Section from "./common/Section"
import dayjs from "dayjs"
import { useForm } from "@formspree/react"

const sessions = [
  {
    title: "Intro session with Meliorist team",
    desc: "Get to know us, who we are and what we could solve for you!",
    time: "15 mins",
  },
  {
    title: "Roadmapping Session (Website)",
    desc: "Let's have a quick look at your business model and discuss how we can get you to market fast!",
    time: "30 mins",
  },
]

const slots = ["11:00 AM", "6:00 PM"]

const Book = ({ formData, setFormData, selected, setSelected }) => {
  const [state, handleSubmit] = useForm("mwkjqzdd")
  const [date, setDate] = useState(null)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }
  const days = Array(7)
    .fill(0)
    .map((_, i) => dayjs().add(i + 1, "day"))

  const handleFormSubmit = (e) => {
    e.preventDefault()
    handleSubmit({
      ...formData,
      date: date.format("YYYY-MM-DD"),
    })
  }

  return (
    <Section
      title="Book a slot"
      desc="Let's have a quick chat and allow us to demonstrate why we're such a great fit!"
      id="book"
      className="small-container"
    >
      <div className="border-2 bg-neutraldark border-secondary rounded-xl w-full font-medium text-[#CDCDCD]">
        {state.succeeded ? (
          <div className="flex flex-col items-center gap-3 py-20 w-full">
            <p className="text-center text-4xl">Appointment Booked!</p>
            <span className="font-semibold text-xl">
              {formData.sessionType}
            </span>
            <span>{date.format("D MMMM, YYYY") + " | " + formData.time}</span>
          </div>
        ) : (
          <>
            <div className="p-6 border-b-2 border-secondary flex flex-col gap-3 items-center">
              <span className="text-3xl">Welcome!</span>
              <p>
                Book your appointment in few simple steps: Choose a service,
                pick your date and time, and fill in your details. See you soon!
              </p>
            </div>
            <div className="flex flex-col lg:flex-row">
              <div className="lg:w-[45%]">
                <Step
                  title={formData.sessionType || "Choose session type"}
                  desc={formData.duration}
                  icon="check"
                  select={() => setSelected(0)}
                  active={0 === selected}
                />
                <Step
                  title={
                    date ? date.format("D MMMM, YYYY") : "Choose date & time"
                  }
                  desc={formData.time}
                  icon="calendar"
                  select={() => setSelected(1)}
                  active={1 === selected}
                  disabled={!formData.sessionType}
                />
                <Step
                  title={"Your info"}
                  icon="user"
                  select={() => setSelected(2)}
                  active={2 === selected}
                  disabled={!date || !formData.time}
                />
              </div>
              <div className="lg:w-[55%] border-l-2 border-secondary">
                <div className="flex flex-col gap-5 p-5 justify-center">
                  {selected === 0 && (
                    <>
                      {sessions.map((session, i) => (
                        <Session
                          key={session.title}
                          {...session}
                          select={() => {
                            setFormData({
                              ...formData,
                              sessionType: session.title,
                              duration: session.time,
                            })
                            setSelected(1)
                          }}
                          active={formData.sessionType === session.title}
                        />
                      ))}
                    </>
                  )}

                  {selected === 1 && (
                    <>
                      <div className="grid grid-cols-4 lg:grid-cols-7 gap-2 pb-5 border-b border-gray-800">
                        {days.map((day) => (
                          <button
                            onClick={() => setDate(day)}
                            className={`rounded-lg border-2 border-secondary shadow p-4 flex flex-col items-center ${
                              date?.format("D") === day.format("D")
                                ? "bg-[#131D2C]"
                                : "bg-transparent"
                            }`}
                          >
                            <span className="text-xl"> {day.format("D")} </span>
                            <span>{day.format("ddd").toUpperCase()}</span>
                          </button>
                        ))}
                      </div>
                      <div className="flex justify-center gap-2">
                        {slots.map((slot) => (
                          <button
                            disabled={!date}
                            onClick={() => {
                              setFormData({
                                ...formData,
                                time: slot,
                              })
                              setSelected(2)
                            }}
                            className={`rounded-lg border-2 border-secondary shadow p-4 flex flex-col items-center ${
                              slot === formData.time
                                ? "bg-[#131D2C]"
                                : "bg-transparent"
                            }`}
                          >
                            <span className=""> {slot} </span>
                          </button>
                        ))}
                      </div>
                    </>
                  )}
                  {selected === 2 && (
                    <form
                      onSubmit={handleFormSubmit}
                      className="lg:px-20 flex flex-col gap-5"
                    >
                      <input
                        type="text"
                        name="name"
                        className="rounded-lg border p-3 w-full"
                        placeholder="Name*"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                      <input
                        type="email"
                        name="email"
                        className="rounded-lg border p-3 w-full"
                        placeholder="Email*"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      <input
                        type="number"
                        name="phone"
                        className="rounded-lg border p-3 w-full"
                        placeholder="Contact Number*"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                      <button
                        type="submit"
                        className="rounded-lg text-white py-2 font-semibold px-8 bg-primary w-auto text-lg flex justify-center items-center gap-3"
                      >
                        {state.submitting
                          ? "..."
                          : state.succeeded
                          ? "Submitted!"
                          : " Schedule Appointment"}
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Section>
  )
}

const Step = ({ title, desc, icon, select, active, disabled }) => (
  <div
    onClick={() => {
      if (!disabled) select()
    }}
    className={`flex px-5 py-4 lg:py-7 gap-3 border-b-2  border-secondary items-center cursor-pointer ${
      active ? "bg-[#131D2C]" : "bg-transparent"
    }`}
  >
    <Icon name={icon} />
    <div className="flex flex-col gap-0 flex-grow">
      <h4 className=" text-lg lg:text-xl text-white font-medium">{title}</h4>
      <p className="text-gray-400">{desc}</p>
    </div>
    <Icon name={active ? "right-1" : "right"} />
  </div>
)

const Session = ({ title, desc, time, select, active }) => (
  <div
    onClick={select}
    className={`flex px-3 lg:px-7 py-3 gap-3 lg:gap-5 border-2  rounded-xl items-center cursor-pointer ${
      active ? "border-primary bg-[#131D2C]" : "border-secondary bg-neutral"
    }`}
  >
    <div className="flex flex-col gap-2 flex-grow">
      <h4 className="text-xl text-white leading-tight">{title}</h4>
      <p className="text-gray-400 leading-tight">{desc}</p>
    </div>
    <span className="flex-none">{time}</span>
    <Icon name={!active ? "checked" : "unchecked"} />
  </div>
)

export default Book
