import React from 'react'
import {Link} from 'react-router-dom'

const Breadcrumb = ({address}) => {
    let arr = (address || 'your/desired/location').split('/')
  return (
    <div className='flex space-x-3 w-[90%]'>
      {arr.map((segment,index)=>{
        return <div className='flex space-x-2'>
            <Link to={`/blog`} className='hover:underline'>{segment }</Link> 
            {index<(arr.length-1)&& (<span>{'>'}</span>)}
            </div>
      })}
    </div>
  )
}

export default Breadcrumb
