export const blogItems = [
    {
        key:1,
        title:'View all',
        value:'all'
    },
    {
        key:2,
        title:'Crypto',
        value:'crypto'
    },
    {
        key:3,
        title:'Blockchain',
        value:'blockchain'
    },
    {
        key:4,
        title:'Announcements',
        value:'announcements'
    },
    {
        key:5,
        title:'People',
        value:'people'
    },
    {
        key:6,
        title:'Engineering',
        value:'engineering'
    },
    {
        key:7,
        title:'NFT',
        value:'nft'
    },
]
