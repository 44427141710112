import React from 'react'

const Cookies = () => {
    return (
        <section className='min-h-[100vh] w-full flex flex-col items-center my-10'>
            <h1 className='font-extrabold text-4xl lg:text-5xl'>Cookies</h1>
            <p className=' w-[80%] lg:w-[60%] text-justify mt-10'>
                When you use our services, you’re trusting us with your information. We understand this is a big responsibility and work hard to protect your information and put you in control.
            </p>
            <div className='flex flex-col space-y-5 w-[90%] lg:w-[80%] mt-14 text-start' >
                <h2 className='font-bold text-lg'>What information we collect, how we collect it, and why</h2>
                <p>
                    Much of what you likely consider personal information is collected directly from you when you:
                    <ol className=' list-decimal mx-8'>
                        <li>create an account or purchase any of our Services (ex: billing information, including name, address, credit card number, government identification);</li>
                        <li>request assistance from our award-winning customer support team (ex: phone number, voice recordings);</li>
                        <li>complete contact forms or request newsletters or other information from us (ex: email); or</li>
                        <li>participate in contests and surveys, apply for a job, or otherwise participate in activities we promote that might require information about you.</li>
                    </ol>
                    However, we also collect additional information when delivering our Services to you to ensure necessary and optimal performance. These methods of collection may not be as obvious to you, so we thought we’d highlight and explain a bit more about what these might be (as they vary from time to time): <br />
                    Cookies and similar technologies on our websites and mobile applications allow us to track your browsing behavior, such as links clicked, pages viewed, and items purchased. Specifically, the data collected relates to your interactions with our site features or third-party features, such as social media plugins, contained within the Services; Internet Protocol (IP) address (for example, to determine your language preference; browser type and settings; the date and time the Services were used; device type and settings; operating system used; application IDs, unique device identifiers; and error data). These technologies also automatically collect data to measure website performance and improve our systems, including metadata, log files, page load time, server response time to optimize DNS resolution, network routing and server configurations. For additional information, and to learn how to manage the technologies we utilize, please visit our <a href="/cookies" className='underline'>Cookie Policy</a> . <br />
                    Supplemented Data may be received about you from other sources, for example, from publicly available databases, social media platforms, or third parties from whom we have purchased data, in which case we may combine this data with information we already have about you so that we can update, expand and analyze the accuracy of our records, assess the qualifications of a candidate for employment, identify new customers, and provide products and services that may be of interest to you. If you provide us personal information about others, or if others give us your information, we will only use that information for the specific reason for which it was provided to us. <br />
                    How we utilize information. <br />
                    We strongly believe in both minimizing the data we collect and limiting its use and purpose to only that (1) for which we have been given permission, (2) as necessary to deliver the Services you purchase or interact with, or (3) as we might be required or permitted for legal compliance or other lawful purposes:
                </p>
                <h2 className='font-bold text-lg'>Delivering, improving, updating and enhancing our Services.</h2>
                <p>
                    We collect various information relating to your purchase, use and/or interactions with our Services. We utilize this information to:
                    <ul className='mx-8 list-disc'>
                        <li>Improve and optimize the operation and performance of our Services (again, including our websites and mobile applications)</li>
                        <li>Diagnose problems with and identify any security and compliance risks, errors, or needed enhancements to the Services</li>
                        <li>Detect and prevent fraud and abuse of our Services and systems</li>
                    </ul>
                    Much of the data collected is aggregated or statistical data about how individuals use our Services, and is not linked to any personal information. <br />
                    Sharing with trusted third parties. We may share your personal information with affiliated companies within our corporate family, with third parties with which we have partnered to allow you to integrate their services into our own Services, and with trusted third party service providers as necessary for them to perform services on our behalf, such as:
                    <ul className='mx-8 list-disc'>
                        <li>Processing credit card payments</li>
                        <li>Serving advertisements (more on this topic below)</li>
                        <li>Conducting contests or surveys</li>
                        <li>Performing analysis of our Services, customer demographics, and sentiment</li>
                        <li>Communicating with you, such as by way of email or survey delivery</li>
                        <li>Customer relationship management</li>
                        <li>Security, risk management and compliance</li>
                        <li>Recruiting support and related services.</li>
                    </ul>
                    These third parties (and any subcontractors they may be permitted to use) have agreed not to share, use or retain your personal information for any purpose other than as necessary for the provision of Services.
                    We will also disclose your information to third parties: <br />
                    <ul className='mx-8 list-disc'>
                        <li>in the event that we sell or buy any business or assets (whether a result of liquidation, bankruptcy or otherwise), in which case we will disclose your data to the prospective seller or buyer of such business or assets; or</li>
                        <li>if we sell, buy, merge, are acquired by, or partner with other companies or businesses, or sell some or all of our assets. In such transactions, your information may be among the transferred assets.</li>
                    </ul>
                </p>
                <h2 className='font-bold text-lg'>Advertisements</h2>
                <p>
                    We want to serve you ads that are most relevant and useful to you, so we may use the personal information you provided us in Account Settings or those collected through cookie technologies to personalize ads and improve your overall experience with us on our site and other sites. We call this ad personalization (formerly, interest-based advertising) because the ads you see are based on previously collected or historical data to determine which ad will be most relevant to you, including data such as previous search queries, activity, visits to sites or apps, demographic information, or location. To illustrate ad personalization that is audience-based, let’s say you registered a domain with us and created a website using our website builder product. The next time you navigate to our advertising partner’s platform or website, they may recognize that you are a GoDaddy customer via a matched email and may use that data to help us display an ad about our latest promotion for website security. Alternatively, if you already purchased our website security product, then we may exclude you from future ad campaigns for this product because it wouldn’t be relevant to you. Other ad personalization could be activity-based. For example, using data about your page views on our site to determine whether an ad would be of interest to you. The information we share with our advertising channel partners to deliver you personalized ads is done securely and cannot be used by them for any other purpose. We do not sell your data to any third parties. In addition, the data we share is hashed and encrypted, which means that it does not directly identify you. We do not sell your data in any way to our partners or other third parties. <br />
                    To manage your preferences for audience-based ad personalization, please sign into your Account and visit “Account Settings.” To manage your preferences for activity-based ad personalization, please visit our <a href="/cookies" className='underline'>Cookie Policy</a>. and go to “Manage Settings.” If you opt-out of ad personalization, you may continue to receive ads, but those ads may be less relevant to you. <br />
                    For more information about ad personalization on your desktop or mobile browser, and to opt out of this type of advertising by third parties that participate in self-regulatory programs, please visit one of the following: the <a href="/" className='underline'>Network Advertising Initiative website</a>, the <a href="/" className='underline'>Digital Advertising Alliance (“DAA”) website</a>  or the <a href="/" className='underline'>European Interactive Digital Advertising Alliance Website</a> . To learn more about ad personalization in mobile apps and to opt out of this type of advertising by third parties that participate in the DAA’s AppChoices tool, please use this URL: https://youradchoices.com/appchoices to download the version of AppChoices for your device. Please note that any opt-out choice you exercise through these programs will only apply to ad personalization by the third parties you select but will still allow the collection of data for other purposes, including research, analytics, and internal operations. You may continue to receive ads, but those ads may be less relevant to you. <br />
                    Communicating with you. We may contact you directly or through a third party service provider regarding products or services you have signed up or purchased from us, such as necessary to deliver transactional or service related communications. We may also contact you with offers for additional services we think you’ll find valuable if you give us consent, or where allowed to contact you based on legitimate interests. You don’t need to provide consent as a condition to purchase our goods or services. These contacts may include:
                    <ul className='mx-8 list-disc'>
                        <li>Email</li>
                        <li>Text (SMS) messages</li>
                        <li>Telephone calls</li>
                        <li>Messenger applications (e.g. WhatsApp, etc.)</li>
                        <li>Automated phone calls or text messages.</li>
                    </ul>
                    You may also update your subscription preferences with respect to receiving communications from us and/or our partners by signing into your account and visiting your “Account Settings” page. <br />
                    If we collect information from you in connection with a co-branded offer, it will be clear at the point of collection who is collecting the information and whose privacy policy applies. In addition, it will describe any options you have regarding the use and/or sharing of your personal information with a co-branded partner, as well as how to exercise those options. We are not responsible for the privacy practices or the content of third-party sites. Please read the privacy policy of any website you visit. <br />
                    If you make use of a service that allows you to import contacts (ex. using email marketing services to send emails on your behalf), we will only use the contacts and any other personal information for the requested service. If you believe that anyone has provided us with your personal information and you would like to request that it be removed from our database, please contact us at <a href="/" className='underline'>privacy@godaddy.com</a> . <br />
                    Transfer of personal information abroad. If you utilize our Services from a country other than the country where our servers are located, your personal information may be transferred across international borders, which will only be done when necessary for the performance of our contract with you, when we have your consent to do so, or subject to the appropriate standard contractual clauses. Also, when you call us or initiate a chat, we may provide you with support from one of our global locations outside your country of origin. <br />
                    Compliance with legal, regulatory and law enforcement requests. We cooperate with government and law enforcement officials and private parties to enforce and comply with the law. We may disclose any information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process (such as subpoena requests), to protect our property and rights or the property and rights of a third party, to protect the safety of the public or any person, or to prevent or stop activity we consider to be illegal or unethical. <br />
                    To the extent we are legally permitted to do so, we may take reasonable steps to notify you if we are required to provide your personal information to third parties as part of legal process. We will also share your information to the extent necessary to comply with any ICANN, registry or ccTLD rules, regulations and policies when you register a domain name with us. For reasons critical to maintaining the security, stability and resiliency of the Internet, this includes the transfer of domain name registration information to the underlying domain registry operator and escrow provider, and publication of that information as required by ICANN in the public WHOIS database or with other third parties that demonstrate a legitimate legal interest to such information. <br />
                    How we secure, store and retain your data. <br />
                    We follow generally accepted standards to store and protect the personal information we collect, both during transmission and once received and stored, including utilization of encryption where appropriate. We retain personal information for as long as required to engage in the uses described in this Privacy Policy, unless a longer retention period is required by applicable law. <br />
                    The criteria used to determine our retention periods include the following:
                </p>


            </div>
        </section>
    )
}

export default Cookies