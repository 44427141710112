import React, { useEffect, useRef, useState } from "react"
import Icon from "./common/Icon"
import Section from "./common/Section"

const items = [
  {
    icon: "person",
    title: "Founder",
    desc: "A founder looking for a CTO and a tech team",
  },
  {
    icon: "startup",
    title: "Startup",
    desc: "A startup looking to hire a tech team for short term",
  },
  {
    icon: "enterprise",
    title: "Enterprise",
    desc: "An enterprise looking for off-shoring opportunities",
  },
  {
    icon: "shop",
    title: "Offline Business",
    desc: "An offline business looking setup an online presence",
  },
]

const texts = [
  {
    question: "What stage are you at in your startup?",
    options: [
      "I have an idea",
      "I need a tech team to build my product",
      "I am looking for a tech co-founder",
    ],
  },
  {
    question: "What kind of assistance do you need in your startup?",
    options: [
      "We need to hire a new tech team",
      "We need a tech co-founder",
      "We need to build a new feature",
      "We need a devops team",
      "We need to scale up",
    ],
  },
  {
    question: "What services is your organization looking out for?",
    options: [
      "We are exploring off-shoring opportunities",
      "We need to hire a new tech team",
      "We need to build a new feature",
      "We need a devops team",
      "We need to scale up",
    ],
  },
  {
    question: "What services do you need in your business?",
    options: [
      "We need to setup a website",
      "We need to redesign our website",
      "We need ERP based solutions for employee and assets management",
    ],
  },
]

const Requirement = ({ setFormData, setSelected }) => {
  const [selected, setselected] = useState(null)

  return (
    <Section
      sx={{ minHeight: "auto" }}
      title="Identify your requirement"
      className="lg:bg-neutraldark rounded-3xl small-container pt-8 pb-6"
      id="requirement"
    >
      <div className="w-full flex flex-col relative lg:flex-row gap-12 pt-5">
        <div className="lg:w-[45%] flex flex-col gap-4">
          {items.map((item, i) => (
            <React.Fragment key={i}>
              <Item
                disabled={selected !== null}
                key={item.title}
                {...item}
                active={i === selected}
                select={() => setselected(i)}
              />
              {selected === i && (
                <div className="lg:hidden max-h-[28rem] overflow-y-scroll ">
                  <ChatBox
                    selected={selected}
                    setSelected={setSelected}
                    setFormData={setFormData}
                  />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="w-[55%] absolute -right-6 hidden h-[96%] lg:block">
          <ChatBox
            selected={selected}
            setSelected={setSelected}
            setFormData={setFormData}
          />
        </div>
      </div>
    </Section>
  )
}

const ChatBox = ({ selected, setSelected, setFormData }) => {
  const divRef = useRef(null);
  useEffect(() => {
    divRef.current.scrollTop = divRef.current.scrollHeight;
  }, [selected]);
  return (
    <div className="border-2 bg-neutral border-secondary rounded-xl h-full">
      <div ref={ divRef} className="flex flex-col gap-3 p-3 max-h-full  overflow-y-scroll lg:p-6">
        <Msg body="Choose a role that best aligns with your requirements and organizational priorities" />
        {selected !== null && (
          <>
            <Msg my icon={items[selected].icon} body={items[selected].title} />
            <Msg body={texts[selected].question} />
            <Options
              icon={items[selected].icon}
              data={texts[selected].options}
              setFormData={setFormData}
              setSelected={setSelected}
            />
          </>
        )}
      </div>
    </div>
  )
}

const Item = ({ disabled, title, desc, icon, active, select }) => (
  <div
    onClick={() => {
      if (!disabled) select()
    }}
    className={`flex px-2 lg:px-5 py-3 gap-1 lg:gap-5 border-2   rounded-xl items-center cursor-pointer ${active ? "border-primary bg-[#131D2C]" : "border-secondary bg-neutral"
      }`}
  >
    <Icon name={icon} />
    <div className="flex flex-col gap-0 flex-grow">
      <h4 className="text-xl font-semibold">{title}</h4>
      <p className="text-gray-400 leading-tight">{desc}</p>
    </div>
    <Icon name={active ? "right-1" : "right"} />
  </div>
)

const Msg = ({ my, icon, body }) => (
  <div
    style={{
      flexDirection: !my ? "row-reverse" : "row",
    }}
    className="flex gap-3"
  >
    <div className="w-10 rounded-full h-10 bg-gray-500 flex items-center justify-center">
      {my ? <Icon name={icon ? icon : "person"} className="w-8" /> : <Icon name="icon" className="w-8" />}
    </div>
    <div
      style={{
        maxWidth: "60%",
        backgroundColor: !my ? "#D9D9D9" : "#B4E2E2",
      }}
      className="rounded-xl px-4 py-2 text-black"
    >
      <p style={{ lineHeight: 1.1 }}>{body}</p>
    </div>
  </div>
)

const Options = ({ icon, data, setFormData, setSelected }) => {
  const [selected, setselected] = useState(null)
  const actions = [
    {
      text: "Contact us",
      action: () => { },
      link: "#contact",
    },
    {
      text: "Book an intro Session",
      action: () => {
        setFormData((val) => ({
          ...val,
          sessionType: "Intro session with Meliorist team",
          duration: "15 mins",
        }))
        setSelected(1)
      },
      link: "#book",
    },
    {
      text: "Book a Roadmapping Session",
      action: () => {
        setFormData((val) => ({
          ...val,
          sessionType: "Roadmapping Session (Website)",
          duration: "30 mins",
        }))
        setSelected(1)
      },
      link: "#book",
    },
  ]
  return (
    <>
      {selected === null && (
        <div className="flex gap-3">
          <div className="w-10 rounded-full h-10 bg-gray-500 flex items-center justify-center">
            <Icon name={icon ? icon : "person"} className="w-8" />
          </div>
          <div className="flex flex-col items-start gap-1">
            {data.map((option, i) => (
              <button
                key={i}
                onClick={() => setselected(i)}
                className="bg-pink-100 text-black hover:bg-pink-200 rounded-lg px-4 py-1 text-left"
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}

      {selected !== null && (
        <>
          {" "}
          <Msg my icon={icon} body={data[selected]} />{" "}
          <Msg body="Sure, we are excited to have you onboard, let’s discuss and align our goals" />
          <div className="flex gap-3">
            <div className="w-10 rounded-full h-10 bg-gray-500 flex items-center justify-center">
              <Icon name={icon ? icon : "person"} className="w-8" />
            </div>
            <div className="flex flex-col items-start gap-1">
              {actions.map((action, i) => (
                <a href={action.link} key={i}>
                  <button
                    onClick={action.action}
                    className="bg-pink-100 text-black hover:bg-pink-200 rounded-lg px-4 py-1 text-left"
                  >
                    {action.text}
                  </button>
                </a>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Requirement
