import React from 'react'
import Icon from '../components/common/Icon'
import { useForm } from '@formspree/react'


const ContactUs = () => {
    const [state, handleSubmit] = useForm("myyajaaa")
    return (
        <section className='w-full flex flex-col items-center  mt-16 mb-28'>
            <h1 className='font-extrabold text-4xl lg:text-5xl'>Contact Us</h1>
            <p className='lg:text-lg mt-5 mx-3'>Fill up the form and our team will get back to you within 24 hours.</p>

            <form onSubmit={handleSubmit} id='contact_form' className='bg-white text-black  px-7 py-10 rounded-2xl w-[90vw] min-h-60% md:w-[80%] lg:w-[40%] my-20 space-y-4' >
                <div className='flex flex-col md:flex-row lg: justify-between w-full gap-4'>
                    <input spellCheck={false} className='h-14 lg:w-[48%] border-2 px-2 focus:outline-none rounded-lg' placeholder='First Name' type="text" name="first_name" id="first_name" />
                    <input spellCheck={false} className='h-14 lg:w-[48%] border-2 px-2 focus:outline-none rounded-lg' placeholder='Last Name' type="text" name="last_name" id="last_name" />
                </div>

                <div className='flex rounded-lg border-2' >
                    <div className='w-24 border-r-2 text-center flex justify-evenly'>
                        <select name="country_code " id="country_code">
                            <option className='text-[#111111]' value="+91">+91</option>
                        </select>
                    </div>
                    <input spellCheck={false} className='h-14 w-full px-2 focus:outline-none' placeholder='Phone Number' type="number" name="phone_number" id="phone_number" />
                </div>

                <input spellCheck={false} className='h-14 w-full border-2 px-2 focus:outline-none rounded-lg' placeholder='Enter Email' type="email" name="email" id="email" />

                <textarea spellCheck={false} name="message" id="message" placeholder='Message' className='w-full rounded-lg focus:outline-none h-24 lg:h-44 p-3 border-2'></textarea>
                <div>
                    <button
                        disabled={state.submitting}
                        type='submit'
                        className='w-full bg-primary rounded-lg text-white text-lg font-semibold py-2'>
                        {state.submitting
                            ? "Submitting..."
                            : state.succeeded
                                ? "Submitted!"
                                : "SEND MESSAGE"}
                    </button>
                </div>
            </form>
            <div className='w-full flex  gap-4 justify-center md:justify-evenly'>
                <a
                    href="//linkedin.com/company/meliorist-developers"
                    className="flex gap-3 text-xl items-center"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Icon name="linkedin" />
                    <span className='hidden lg:inline'>meliorist-developers</span>
                </a>
                <a
                    href="//twitter.com/meliorist_dev"
                    className="flex gap-3 text-xl items-center"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Icon name="twitter" />
                    <span className='hidden lg:inline'>@meliorist_dev</span>
                </a>
                <a
                    href="//discord.gg/5WPmzv9UKe"
                    className="flex gap-3 text-xl items-center"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Icon name="discord" />
                    <span className='hidden lg:inline'>#meliorist.dev</span>
                </a>
            </div>
        </section>
    )
}

export default ContactUs
