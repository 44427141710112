import { useForm } from "@formspree/react"
import React from "react"

const Newsletter = ({title = 'Be part of the inner circle'}) => {
  const [state, handleSubmit] = useForm("mvonqyjn")
  return (
    <div id="newsletter" className="bg-neutraldark py-16 flex flex-col gap-3 lg:flex-row justify-between items-center px-5 lg:px-36 w-full">
      <div className="w-full">
        <h4 className="text-3xl lg:text-4xl font-bold mb-2">
          {title}
        </h4>
        <p className="text-lg">
          Stay up to date with our latest products and announcements
        </p>
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col lg:justify-end lg:flex-row gap-3 w-full"
      >
        <input
          name="email"
          type="email"
          className="rounded-lg border p-3 text-lg lg:w-96 flex-none text-black"
          placeholder="Enter your email"
          required
        />
        <button
          type="submit"
          className="w-full lg:w-auto rounded-lg border-2 border-primary text-lg font-semibold px-6 py-3"
        >
          {state.submitting
            ? "..."
            : state.succeeded
            ? "SUBSCRIBED!"
            : "SUBSCRIBE"}
        </button>
      </form>
    </div>
  )
}

export default Newsletter
