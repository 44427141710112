import React from "react"
import Section from "./common/Section"

const steps = [
  {
    title: "Scoping",
    desc: "Streamline the purpose and the scope of your product with our carefully curated questionnaire",
  },
  {
    title: "Validation",
    desc: "Get your idea validated with the help of our network of experts across diverse backgrounds",
  },
  {
    title: "MVP",
    desc: "Build the MVP of your product with our battle proven tech stack that will get you started without incurring huge infra bills",
  },
  {
    title: "Scale up",
    desc: "Scale your product to add great features and handle larger traffic with our agile based development approach",
  },
]

const Working = () => {
  return (
    <Section
      title="How it works"
      desc="Our core team leverages a diverse pool of talented Freelancers & Part-timers to provide right skill match, flexible development plan, state-of-the-art expertise, and cost efficiencies far better than industry standards"
      id="how-it-works"
      className="mt-36 lg:mt-0"
    >
      <div className="flex items-center w-full bg-[#3C3252] rounded-3xl overflow-hidden ">
        <div className="hidden lg:block w-1/2">
          <img
            src="/assets/images/rocket.gif"
            alt="rocket"
            className="w-full"
          />
        </div>
        <div className="lg:w-1/2 flex flex-col justify-center pl-12 lg:pl-0 pt-5">
          {steps.map((step, i) => (
            <Step key={step.title} num={i + 1} {...step} />
          ))}
        </div>
      </div>
    </Section>
  )
}

const Step = ({ num, title, desc }) => {
  return (
    <div
      style={{
        borderLeft: num !== 4 ? "4px solid white" : "none",
      }}
      className="flex flex-col gap-1 font-medium pl-10 pr-5 lg:pl-16 lg:pr-16 relative pb-4"
    >
      <div className="w-14 rounded-full h-14 absolute top-0 -left-7 bg-white text-[#3C3252] flex justify-center items-center text-2xl">
        {num}
      </div>
      <h4 className="text-xl">{title}</h4>
      <p className="text-base text-gray-400">{desc}</p>
    </div>
  )
}

export default Working
