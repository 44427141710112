export const createSlug = (text) => {
    if (!text) throw new Error('Text is required for slug creation');
    return text
      .toLowerCase()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
};
  
export const getBlogBySlug = async (topic, slug) => {
    if (!topic || !slug) {
      throw new Error('Both topic and slug are required');
    }
    
    try {
      const module = await import(`../pages/blogs/${topic}/${slug}.jsx`);
      return module.post;
    } catch (error) {
      console.error(`Blog post not found: ${topic}/${slug}`);
      return null;
    }
};

export const getBlogsByTopic = (topic) => {
    const posts = [];
    
    // Using webpack require.context with recursive true
    const context = require.context('../pages/blogs', true, /\.jsx$/);
    
    context.keys().forEach((key) => {
        // Only process files that match the topic and aren't index files
        if (key.includes(`/${topic}/`) && !key.includes('index.jsx')) {
            try {
                // Get the module
                const blogModule = context(key);
                
                // Extract category and slug from the path
                const pathParts = key.slice(2).split('/');
                const category = pathParts[0];
                const slug = pathParts[1].replace('.jsx', '');
                
                // Check if the module has a post export
                if (blogModule.post) {
                    posts.push({
                        ...blogModule.post,
                        category,
                        slug,
                        path: `${category}/${slug}`
                    });
                }
            } catch (error) {
                console.error(`Error loading blog post from ${key}:`, error);
            }
        }
    });
    
    // Sort posts by date (most recent first)
    return posts.sort((a, b) => new Date(b.date) - new Date(a.date));
};

export const getAllBlogs = () => {
    const posts = [];
    
    // Using webpack require.context with recursive true
    // This will look through all subdirectories
    const context = require.context('../pages/blogs', true, /\.jsx$/);
    
    context.keys().forEach((key) => {
        // Skip index files
        if (!key.includes('index.jsx')) {
            try {
                // Get the module
                const blogModule = context(key);
                
                // Extract category and slug from the path
                // Remove './' from the beginning and split
                const pathParts = key.slice(2).split('/');
                const category = pathParts[0];
                const slug = pathParts[1].replace('.jsx', '');
                
                // Check if the module has a post export
                if (blogModule.post) {
                    posts.push({
                        ...blogModule.post,
                        category,
                        slug,
                        path: `${category}/${slug}`
                    });
                }
            } catch (error) {
                console.error(`Error loading blog post from ${key}:`, error);
            }
        }
    });
    
    // Sort posts by date (most recent first)
    return posts.sort((a, b) => new Date(b.date) - new Date(a.date));
};
