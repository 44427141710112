import React, { useState } from "react"
import Icon from "./common/Icon"
import Section from "./common/Section"

const items = [
  {
    title: "Assetzing",
    sub: "Enterprise Software",
    desc: "A Simple one-stop solution for Large Enterprises with on-ground Operations",
    features: [
      {
        img: "worker",
        text: "Employee & Facility Management",
      },
      {
        img: "task",
        text: "Task & Work Order Scheduling",
      },
      {
        img: "stock",
        text: "Asset & Inventory Management",
      },
      {
        img: "ticket",
        text: "Ticketing & SLAs",
      },
    ],
  },
  {
    title: "Optika",
    sub: "Smart Cameras",
    desc: "AI-Powered Solution for Managing Visual Pollution and Smart Cities",
    features: [
      {
        img: "tarck",
        text: "Live Tracking & Insights",
      },
      {
        img: "cost",
        text: "Portable & cost-effective",
      },
      {
        img: "data",
        text: "Data assisted Prediction",
      },
      {
        img: "diagram",
        text: "Smart Asset Allocation",
      },
    ],
  },
  {
    title: "Youstad",
    sub: "Virtual Classroom",
    desc: "Integrated Learning Platform to optimize academy operations and streamline workload",
    features: [
      {
        img: "class",
        text: "Course & Class Management",
      },
      {
        img: "invoice",
        text: "Cash Register with Invoice",
      },
      {
        img: "graph",
        text: "Performance Tracker",
      },
      {
        img: "discussion",
        text: "Discussion Room",
      },
    ],
  },
  {
    title: "Scan-n-Pay",
    sub: "Scan Payment Method",
    desc: "Faster contactless payment using QR Codes",
    features: [
      {
        img: "pos",
        text: "POS Integration",
      },
      {
        img: "update",
        text: "Live Payment Updates",
      },
      {
        img: "divide",
        text: "Live Bill Splitting",
      },
      {
        img: "gift",
        text: "Friends Network & Rewards",
      },
    ],
  },
  {
    title: "VIC",
    sub: "Virtual Immersive Cycling",
    desc: "An immersive experience of cycling across streets and valleys, from the comfort of your Living Room",
    features: [
      {
        img: "bicycle",
        text: "Smart Trainer Compatibility",
      },
      {
        img: "checklist",
        text: "Custom Training Modules",
      },
      {
        img: "devices",
        text: "Cross-platform Compatibility",
      },
      {
        img: "integrate",
        text: "Training Data Integration",
      },
    ],
  },
  {
    title: "Quicko",
    sub: "Hyperlocal Delivery",
    desc: "Optimized routing system for fastest delivery time, GUARANTEED!",
    features: [
      {
        img: "clothes",
        text: "Geography-agnostic Hubs",
      },
      {
        img: "transfer",
        text: "Relay System",
      },
      {
        img: "pricing",
        text: "Customizable Pricing Model",
      },
      {
        img: "payment",
        text: "Multi Payment Method",
      },
    ],
  },
  {
    title: "Docket",
    sub: "e - Classroom",
    desc: "Plan with precision and never miss a class again",
    features: [
      {
        img: "course",
        text: "Course Planner",
      },
      {
        img: "timetable",
        text: "Timetable Management",
      },
      {
        img: "routine",
        text: "Automatic Scheduling",
      },
      {
        img: "assistant",
        text: "Scheduling Assistant",
      },
    ],
  },
  {
    title: "Gully101",
    sub: "e - Commerce",
    desc: "Multi-vendor Marketplace with Democracy in Pricing",
    features: [
      {
        img: "credit",
        text: "POS with Inventory Management",
      },
      {
        img: "list",
        text: "Simplified Product Listing",
      },
      {
        img: "sale",
        text: "Sales Analytics",
      },
      {
        img: "market",
        text: "Marketing and SEO Agnostic",
      },
    ],
  },
]

const Portfolio = () => {
  const [current, setCurrent] = useState(1)
  const [freeze, setFreeze] = useState(false)
  const next = () => {
    if (current === items.length) {
      setCurrent(current + 1)
      setTimeout(() => {
        setFreeze(true)
        setCurrent(1)
        setTimeout(() => {
          setFreeze(false)
        }, 10)
      }, 700)
    } else setCurrent(current + 1)
  }
  const prev = () => {
    if (current === 1) {
      setCurrent(current - 1)
      setTimeout(() => {
        setFreeze(true)
        setCurrent(items.length)
        setTimeout(() => {
          setFreeze(false)
        }, 10)
      }, 700)
    } else setCurrent(current - 1)
  }
  return (
    <Section title="Our Portfolio" id="portfolio">
      <div className="w-full flex gap-5 lg:gap-16 items-center justify-center pt-10">
        <button onClick={prev}>
          <Icon name="next" className="w-10 lg:w-auto" />
        </button>
        <div style={{ width: "60vw" }} className="flex-none overflow-hidden">
          <div
            style={{
              marginLeft: -1 * current * 65 + "vw",
              transitionProperty: "margin-left",
              transitionDuration: freeze ? "0s" : "0.6s",
              gap: "5vw",
            }}
            className="flex"
          >
            <Item {...items[items.length - 1]} idx={items.length - 1} />
            {items.map((item, i) => (
              <Item key={i} {...item} idx={i} />
            ))}
            <Item {...items[0]} idx={0} />
          </div>
        </div>

        <button onClick={next} className="w-10 lg:w-auto">
          <Icon name="prev" />
        </button>
      </div>
      <div className="flex gap-2 lg:gap-8 mt-16 justify-center">
        <button
          className={`p-2 ${false ? "border-2 border-primary rounded-lg" : ""}`}
        >
          <Icon
            name={current < 3 ? items.length - 3 + current : current - 3}
            png
            className="w-16"
          />
        </button>
        <button
          className={`p-2 ${false ? "border-2 border-primary rounded-lg" : ""}`}
        >
          <Icon
            name={current < 2 ? items.length - 2 + current : current - 2}
            png
            className="w-16"
          />
        </button>
        <button
          className={`p-2 ${true ? "border-2 border-primary rounded-lg" : ""}`}
        >
          <Icon
            name={
              current === 0
                ? items.length - 1
                : current === items.length
                ? items.length - 1
                : current === items.length + 1
                ? 0
                : current - 1
            }
            png
            className="w-16"
          />
        </button>
        <button
          className={`p-2 ${false ? "border-2 border-primary rounded-lg" : ""}`}
        >
          <Icon
            name={current > items.length - 1 ? current - items.length : current}
            png
            className="w-16"
          />
        </button>
        <button
          className={`p-2 ${false ? "border-2 border-primary rounded-lg" : ""}`}
        >
          <Icon
            name={
              current + 1 > items.length - 1
                ? current + 1 - items.length
                : current + 1
            }
            png
            className="w-16"
          />
        </button>
      </div>
    </Section>
  )
}

const Item = ({ idx, title, sub, desc, features }) => (
  <div
    style={{ width: "60vw" }}
    className="flex-none font-medium text-[#CDCDCD]"
  >
    <div className="flex flex-col lg:flex-row items-center gap-8">
      <Icon name={idx} png className="lg:w-[45%]" />

      <div className="lg:w-[55%]">
        <div className="flex flex-col gap-5">
          <div>
            <h4 className="text-4xl text-center lg:text-left font-semibold mb-1">
              {title}
            </h4>
            <p className="text-xl text-center lg:text-left">{sub}</p>
          </div>
          <p className="text-center lg:text-left">{desc}</p>
          <div>
            <h5 className="text-xl font-semibold mb-3 text-center lg:text-left">
              Features
            </h5>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
              {features.map((feature, i) => (
                <div key={i} className="px-3 py-2 flex gap-2 items-center font-normal bg-[#14202E] rounded-xl">
                  <Icon name={feature.img} />
                  {feature.text}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Portfolio
