import React from "react"

const Icon = ({ png, name, className }) => {
  return (
    <img
      className={className}
      src={"/assets/images/" + name + (png ? ".png" : ".svg")}
      alt={name}
    />
  )
}

export default Icon
