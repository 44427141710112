import React from "react"
import Icon from "./Icon"
import Section from "./Section"

const links = [
  {
    title: "Company",
    links: [
      {
        link: "/contact",
        name: "Contact Us",
      },
      {
        link: "https://www.linkedin.com/company/meliorist-developers/jobs",
        name: "Careers",
      },
      {
        link: "/blog",
        name: "Blog",
      },
      {
        link: "/news",
        name: "Newsroom",
      },
    ],
  },
  {
    title: "Social",
    links: [
      {
        link: "https://linkedin.com/company/meliorist-developers",
        name: "LinkedIn",
      },
      {
        link: "https://twitter.com/meliorist_dev",
        name: "Twitter",
      },
      {
        link: "https://discord.gg/5WPmzv9UKe",
        name: "Discord",
      },

      {
        link: "https://github.com/meliorist-dev",
        name: "Github",
      },
    ],
  },
  {
    title: "Legal",
    links: [
      {
        link: "/terms",
        name: "Terms",
      },
      {
        link: "/privacy",
        name: "Privacy",
      },
      {
        link: "/cookies",
        name: "Cookies",
      },
    ],
  },
]

const Footer = () => {
  return (
    <Section sx={{ minHeight: "auto", marginBottom: 0 }}>
      <div className="w-full flex flex-col gap-5 lg:flex-row border-b pb-10 border-gray-500">
        <div className="lg:w-1/2 flex justify-center flex-col items-center gap-6 lg:gap-16">
          <Icon name="logo" />
          <p className="text-center text-gray-400 lg:w-3/5">
            We design and build sophisticated software solutions to help our
            clients realize their long-term goals
          </p>
        </div>
        <div className="lg:w-1/2 flex justify-center gap-10 lg:gap-20">
          {links.map((l, i) => (
            <FooterLinkBlock key={i} {...l} />
          ))}
        </div>
      </div>
      <div className="flex justify-between items-center w-full pt-10">
        <span className="text-sm text-gray-400">
          © Meliorist Developers Private Limited 2023, All Rights Reserved
        </span>
        <div className="flex gap-6">
          <a
            href="//linkedin.com/company/meliorist-developers"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="linkedin-1" />
          </a>
          <a
            href="//twitter.com/meliorist_dev"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="twitter-1" />
          </a>
          <a
            href="//discord.gg/5WPmzv9UKe"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="discord-1" />
          </a>
        </div>
      </div>
    </Section>
  )
}

const FooterLinkBlock = ({ title, links }) => (
  <div>
    <h5 className="text-xl mb-5 text-gray-300">{title}</h5>
    <div className="flex flex-col gap-3">
      {links.map((link, i) => (
        <a key={i} href={link.link} target="_blank" rel="noopener noreferrer">
          {link.name}
        </a>
      ))}
    </div>
  </div>
)

export default Footer
