import React from "react"
import Icon from "./common/Icon"
import Section from "./common/Section"

const clients = [
  {
    name: "Ministry of Health",
    country: "Saudi Arabia",
    icon: "mohksa",
  },
  {
    name: "Maestro Pizza",
    country: "Saudi Arabia",
    icon: "maestro",
  },
  {
    name: "Tata Nexarc",
    country: "India",
    icon: "nexarc",
  },
  {
    name: "sainapse.ai",
    country: "USA/India",
    icon: "sainpase",
  },
  {
    name: "Boussole Pro",
    country: "Morocco",
    icon: "boussole",
  },
  {
    name: "RGIT",
    country: "India",
    icon: "rgit",
  },
]

const Clients = () => {
  return (
    <Section
      title="Our clients"
      desc="Privileged to be partnering with exceptional organizations, cultivating trust and driving mutual success as strategic allies."
      sx={{
        paddingLeft: "0rem",
        paddingRight: "0rem",
      }}
    >
      <div className="w-full bg-white py-10 text-black">
        <h4 className="text-gray-400 text-center mb-5 text-lg">
          Esteemed organisations who are our clients
        </h4>
        <div className="flex flex-wrap gap-5 lg:gap-16 justify-center items-end">
          {clients.map((client, i) => (
            <div key={i} className="flex flex-col items-center gap-1">
              <div className="h-20 flex items-center">
                <Icon name={client.icon} />
              </div>

              <span className="text-xl font-medium">{client.name}</span>
              <span className="text-sm font-thin">{client.country}</span>
            </div>
          ))}
        </div>
      </div>
    </Section>
  )
}

export default Clients
