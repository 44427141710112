import React from "react"
import Icon from "./common/Icon"
import Section from "./common/Section"

const Pricing = () => {
  return (
    <Section title="Pricing" sx={{ paddingTop: "8rem" }}>
      <div className="flex flex-col lg:flex-row lg:px-28 items-center">
        <div className="lg:w-1/3">
          <Icon name="card" png />
        </div>
        <div className="lg:w-2/3 flex flex-col gap-7 text-lg text-[#CDCDCD]">
          <p>
            We believe everyone has a unique requirement so we prefer to sit
            down with you to scope your requirement before offering you a
            quotation. Our pricing structure is completely transparent and is
            far below the market prices and we charge you based on the
            development effort estimate.
          </p>
          <p>
            We build solutions with up-to-date tech stack so you don’t have
            problems finding developers in future to build your in-house team.
            We are here to build partnerships not to provide technical
            consultancy so we offer revenue share deals when liquidity is a
            problem.
          </p>
        </div>
      </div>
    </Section>
  )
}

export default Pricing
