import React, { useEffect, useState } from "react"
import Icon from "./common/Icon"
import Section from "./common/Section"

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  })
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  return windowSize
}

const items = [
  {
    img: "https://assets-global.website-files.com/60b3b99e9d53e457aa9ea3bf/64f6d0a556907cba87cc5c5c_Ellipse-5.png",
    body: "I admired the team's innovative yet efficient solutions, coupled with their commitment to transparency during the work process. They demonstrate patience, dedication, and consistently champion the success of the project.",
    name: "Omar Homsi, Maestro Pizza",
  },
  // {
  //   img: "",
  //   body: "",
  //   name: "",
  // },
  // {
  //   img: "",
  //   body: "",
  //   name: "",
  // },
  // {
  //   img: "",
  //   body: "",
  //   name: "",
  // },
  // {
  //   img: "",
  //   body: "",
  //   name: "",
  // },
  // {
  //   img: "",
  //   body: "",
  //   name: "",
  // },
]

const Testimonials = () => {
  const size = useWindowSize()
  const [current, setCurrent] = useState(0)
  const [freeze, setFreeze] = useState(false)
  const next = () => {
    if (current === items.length) {
      setCurrent(current + 1)
      setTimeout(() => {
        setFreeze(true)
        setCurrent(1)
        setTimeout(() => {
          setFreeze(false)
        }, 10)
      }, 600)
    } else setCurrent(current + 1)
  }
  const prev = () => {
    if (current > 0) setCurrent(current - 1)
  }
  return (
    <>
      <Section sx={{ marginBottom: 0, minHeight: "auto" }}>
        <Icon name="heart" className="mb-8" />
        <div className="w-full flex justify-between mb-8">
          <h3 className="text-4xl lg:text-5xl font-bold w-1/2">
            Our customers are loving us.
          </h3>
          <div>
            <button disabled={!current} onClick={prev}>
              <Icon
                name={current ? "right-1" : "right"}
                className="rotate-180"
              />
            </button>
            <button onClick={next}>
              <Icon name="right-1" />
            </button>
          </div>
        </div>
      </Section>
      <div className="w-screen overflow-hidden">
        <div
          style={{
            marginLeft:
              -1 * current * 380 + (size.width >= 1024 ? 144 : 15) + "px",
            transitionProperty: "margin-left",
            transitionDuration: freeze ? "0s" : "0.6s",
            gap: "30px",
          }}
          className="flex"
        >
          {items.map((item, i) => (
            <Testimonial key={i} {...item} />
          ))}
        </div>
      </div>
      <Section sx={{ minHeight: "auto" }}>
        <div className="flex gap-5">
          {items.map((_, i) => (
            <div
              key={i}
              onClick={() => setCurrent(i)}
              className={`rounded-full w-10 h-1 cursor-pointer ${
                current === i ? "bg-primary" : "bg-white"
              }`}
            ></div>
          ))}
        </div>
      </Section>
    </>
  )
}

const Testimonial = ({img, body, name}) => (
  <div className="rounded-xl border p-7 w-[350px] flex flex-col gap-5 flex-none">
    <img src={img} alt={name} className="w-14 h-14 rounded-full bg-white"></img>
    <p>
      {body}
    </p>
    <span className="text-lg font-medium">- @{name}</span>
  </div>
)

export default Testimonials
