import React from "react"
import Icon from "./common/Icon"

const Hero = () => {
  return (
    <div className="py-4 h-[90vh] flex flex-col lg:flex-row gap-5 lg:gap-[15rem] px-5 lg:px-36 pb-20">
      <div className="lg:w-1/2 flex flex-col justify-center gap-7 items-start">
        <h1 className="text-4xl lg:text-6xl font-bold pt-10 lg:pt-0">
          Programming across time zones
        </h1>
        <p className="text-text text-lg">
          We build sophisticated, user-centric solutions for our clients to help
          them realize their long-term goals
        </p>
        <a href="#how-it-works">
          <button className="rounded-lg py-2 font-semibold px-8 bg-primary w-auto text-lg flex items-center gap-3">
            How it works <Icon name="arrow" className="w-2" />
          </button>
        </a>
      </div>
      <div className="lg:w-1/2 flex flex-col justify-center">
        <Icon name="hero" png />
      </div>
    </div>
  )
}

export default Hero
