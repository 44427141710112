import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getAllBlogs, getBlogsByTopic, createSlug } from '../utilities/Blog';
import { blogItems } from '../constants';
import { ReactComponent as RightArrow } from '../assets/icons/rightArrow.svg';
import Newsletter from '../components/Newsletter';
import Icon from '../components/common/Icon';

const MajorBlogCard = ({ title, subject, description, heroImage, readTime, date }) => {
    const slug = createSlug(title);

    return (
        <div className='w-[85%] h-[68vh] bg-white mt-20 lg:flex hidden rounded-md'>
            <Icon name={heroImage} className='max-h-full w-[60%] rounded-md' png />
            <div className='h-[100%] w-[40%] flex flex-col justify-between p-10'>
                <div className='space-y-5'>
                    <h2 className='text-black'>
                        <span className='text-white bg-[#111111] py-2 px-3 rounded-sm mr-3'>
                            {subject}
                        </span>
                        {readTime}
                    </h2>
                    <h1 className='text-4xl font-bold text-black'>{title}</h1>
                    <p className='text-[#111111]'>{description}</p>
                    <p className='text-gray-600'>{date}</p>
                </div>
                <div>
                    <Link
                        to={`/blog/${subject.toLowerCase()}/${slug}`}
                        className='px-4 py-2 text-black border-[1.5px] font-bold border-black rounded-sm flex items-center space-x-2'
                    >
                        <span>Read more</span>
                        <RightArrow />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export const BlogCard = ({ title, subject, description, heroImage, readTime, date }) => {
    const maxLength = 142;
    const slug = createSlug(title);

    return (
        <div className='lg:w-[26.5vw] h-[75vh] border-2 rounded-md max-w-4xl'>
            <Icon name={heroImage} className='w-full h-[50%] aspect-auto' png />
            <div className='h-[50%] w-full bg-white flex flex-col justify-between p-5'>
                <div className='space-y-2'>
                    <h2 className='text-black'>
                        <span className='text-white bg-[#111111] py-1 px-2 rounded-sm mr-3'>
                            {subject}
                        </span>
                        {readTime}
                    </h2>
                    <h1 className='text-xl font-bold text-black'>{title}</h1>
                    <p className='text-[#111111] text-sm'>
                        {description.length > maxLength
                            ? `${description.slice(0, maxLength)}...`
                            : description}
                    </p>
                    <p className='text-gray-600 text-sm'>{date}</p>
                </div>
                <div>
                    <Link
                        to={`/blog/${subject.toLowerCase()}/${slug}`}
                        className='px-4 py-2 text-black border-[1.5px] border-black rounded-sm flex items-center space-x-2 font-bold'
                    >
                        <span>Read more</span>
                        <RightArrow />
                    </Link>
                </div>
            </div>
        </div>
    );
};

const Blog = () => {
    const navigate = useNavigate();
    const { subject } = useParams();
    const [blogItem, setBlogItem] = useState(subject?.toLowerCase() || 'all');
    const [blogPosts, setBlogPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadPosts = async () => {
            try {
                setLoading(true);
                const posts = blogItem === 'all'
                    ? await getAllBlogs()
                    : await getBlogsByTopic(blogItem);

                // Sort posts by date (newest first)
                const sortedPosts = posts.sort((a, b) =>
                    new Date(b.date) - new Date(a.date)
                );

                setBlogPosts(sortedPosts);
            } catch (error) {
                console.error('Error loading blog posts:', error);
                setBlogPosts([]);
            } finally {
                setLoading(false);
            }
        };

        loadPosts();
    }, [blogItem]);

    const handleCategoryChange = (value) => {
        setBlogItem(value);
        navigate(value !== 'all' ? `/blog/${value.toLowerCase()}` : '/blog');
    };

    if (loading) {
        return (
            <div className="w-full h-[50vh] flex items-center justify-center">
                <p>Loading posts...</p>
            </div>
        );
    }

    return (
        <section className='w-full min-h-[100vh] flex flex-col items-center mt-5 mb-[20vmin]'>
            <div className='w-full flex flex-col items-center text-center space-y-8'>
                <h1 className='text-4xl lg:text-6xl'>Meddling in Meliorism</h1>
                <p className='w-[90%] lg:w-[30%]'>
                    Peek into how we're shaping solutions for the future, discover our latest
                    product updates, partnership announcements, user stories, and more.
                </p>
                <div className='flex bg-white p-[5px] space-x-1 w-[90%] lg:w-auto overflow-x-scroll
                    lg:overflow-x-hidden rounded-md h-10'>
                    {blogItems.map((item) => (
                        <button
                            key={item.key}
                            onClick={() => handleCategoryChange(item.value)}
                            className={`py-1 rounded-[3px] px-3 text-sm focus:outline-none whitespace-nowrap
                                ${blogItem === item.value ? 'bg-[#444BFF] text-white' : 'text-black'}`}
                        >
                            {item.title}
                        </button>
                    ))}
                </div>
            </div>

            {blogPosts.length > 0 && <MajorBlogCard {...blogPosts[0]} />}

            <div className='my-16 w-[85%] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-12'>
                {blogPosts.slice(1).map((post, index) => (
                    <BlogCard key={`${post.title}-${index}`} {...post} />
                ))}
            </div>

            {blogPosts.length === 0 && (
                <div className="w-full h-[30vh] flex items-center justify-center">
                    <p>No posts found for this category.</p>
                </div>
            )}

            <Newsletter />
        </section>
    );
};

export default Blog;