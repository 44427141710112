import React from "react"
import Icon from "./common/Icon"
import Section from "./common/Section"

const items = [
  {
    title: "Scoping",
    desc: "Get revenue",
    time: "2 weeks",
    product:
      "A website and process that can solve your problem for your customers",
    price: "999",
    color: "rgba(82, 155, 245, 0.2)",
  },
  {
    title: "Design",
    desc: "Design your product",
    time: "4 weeks",
    product:
      "Clickable mockup and clear roadmap and reliable numbers for you and investors",
    price: "2999",
    color: "rgba(243, 200, 78, 0.2)",
  },
  {
    title: "MVP",
    desc: "Build the product",
    time: "10 weeks",
    product:
      "A focused solution platform that swiftly tackles critical customer challenges",
    price: "10k-60k",
    color: "rgba(16, 174, 98, 0.2)",
  },
  {
    title: "Run & Scale",
    desc: "Get customers",
    time: "Continuous",
    product:
      "Enhancing customer base and refining conversion funnels using valuable feedback",
    price: "4k+",
    color: "rgba(176, 131, 240, 0.2)",
  },
]

const Quoting = () => {
  return (
    <Section
      title="Our Quoting Process"
      desc="We love transparency: we’ve listed our prices in clear, transparent terms, so that you can plan e.g. for your first financing round. The pricing indications below apply only for early stage startups since they are subsidized - please contact us if you are an innovation team."
    >
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 pt-10 text-[#DEE1E2]">
        {items.map((item) => (
          <Item key={item.title} {...item} />
        ))}
      </div>
    </Section>
  )
}

const Item = ({ title, desc, time, product, price, color }) => (
  <div className="border-2 bg-neutral border-secondary rounded-xl overflow-hidden">
    <div
      style={{
        backgroundColor: color,
      }}
      className="px-4 py-5 flex flex-col gap-1"
    >
      <h4 className="font-bold text-3xl">{title}</h4>
      <span className="text-xl">{desc}</span>
    </div>
    <div className="px-4">
      <div className="flex items-start gap-3 py-5 border-b-2 border-secondary text-[#9E9E9E]">
        <Icon name="clock" />
        <span>{time}</span>
      </div>
      <div className="flex items-start gap-3 py-5 border-b-2 border-secondary text-[#9E9E9E]">
        <Icon name="gift" />
        <span>{product}</span>
      </div>
      <div className="py-3 mt-1 font-bold  flex justify-center items-end">
        <span className="text-5xl">{price}</span>
        <span className="text-3xl">$</span>
      </div>
    </div>
  </div>
)

export default Quoting
