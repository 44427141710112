import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToView() {
  const { pathname } = useLocation();
  const scrollToView = () => {
    const urlHash = window.location.hash;
    if (urlHash.length) {
      const element = document.getElementById(urlHash.substring(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }

  useEffect(() => {
    scrollToView();
  }, [pathname]);

  return null;
}
